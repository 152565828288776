module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"ТПК","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/tpk-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2124403d10ac1bd981475da776fc4bd0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["ua","ru","en"],"defaultLanguage":"ua","path":"/Users/ernest/Desktop/GatsbyProjects/Done/TPK/tpk/app/locales","siteUrl":"https://tpkoil.com","i18nextOptions":{"debug":true,"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/ignored-page","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
