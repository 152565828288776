/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/scss/bootstrap-grid.scss";
import "bootstrap/scss/bootstrap-reboot.scss";
import "bootstrap/scss/mixins/_breakpoints.scss";
import "./src/styles/style.sass";

import "fontsource-inter/cyrillic-ext-400.css";
import "fontsource-inter/cyrillic-ext-500.css";
import "fontsource-inter/cyrillic-ext-600.css";
import "fontsource-inter/cyrillic-ext-700.css";

// import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css'
