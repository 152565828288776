// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-company-js": () => import("./../../src/pages/about-company.js" /* webpackChunkName: "component---src-pages-about-company-js" */),
  "component---src-pages-contacts-js": () => import("./../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-ignored-page-js": () => import("./../../src/pages/ignored-page.js" /* webpackChunkName: "component---src-pages-ignored-page-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logistics-js": () => import("./../../src/pages/logistics.js" /* webpackChunkName: "component---src-pages-logistics-js" */),
  "component---src-pages-page-2-js": () => import("./../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-petroleum-products-js": () => import("./../../src/pages/petroleum-products.js" /* webpackChunkName: "component---src-pages-petroleum-products-js" */),
  "component---src-pages-storage-js": () => import("./../../src/pages/storage.js" /* webpackChunkName: "component---src-pages-storage-js" */)
}

